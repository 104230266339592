/*

NOTE: tag is not supported in horizontal menu

Array of object

Top level object can be:
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- nav Grp (top level grp is called header group) ---/

title
icon (if it's on top/second level)
children

/--- nav Item (top level item is called header link) ---/

icon (if it's on top/second level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)

*/
// import dashboard from './dashboard'
// import apps from './apps'
// import pages from './pages'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'
// import others from './others'
import customer from './customer'
import employer from './employer'
import product from './product'
import sell from './sell'
import warehouse from './warehouse'
import setting from './setting'

// Array of sections
export default [...sell, ...warehouse, ...setting, ...product, ...customer, ...employer]
