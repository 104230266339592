export default [
  {
    header: 'สินค้า',
    icon: 'CopyIcon',
    children: [
      {
        title: 'สินค้า',
        route: 'product_list'
      },
      {
        title: 'เพิ่มสินค้า',
        route: 'product_add'
      }
    ]
  }
]
