export default [
  {
    header: 'พนักงาน',
    icon: 'UserIcon',
    children: [
      {
        title: 'พนักงาน',
        route: 'employer_list'
      },
      {
        title: 'เพิ่มพนักงาน',
        route: 'employer_add'
      }
    ]
  }
]
