export default [
  {
    header: 'ลูกค้า & ตัวแทน',
    icon: 'UserIcon',
    children: [
      {
        title: 'จัดการลูกค้า',
        route: 'customer_list'
      },
      {
        title: 'จัดการตัวแทน',
        route: 'agent_list'
      },
      {
        title: 'เพิ่มลูกค้า',
        route: 'customer_add'
      }
    ]
  }
]
